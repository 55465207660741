

export default {
  name: 'HomePageImagesBanners',

  props: {
    api: {
      type: Object,
      default: null,
    },
  },

  methods: {
    onBannerClick (item) {
      // gtm
    },

    isUrlRelative (url) {
      return url && url[0] === '/'
    },
  },
}
